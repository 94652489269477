
import { Component, Prop, Vue } from 'vue-property-decorator';
import '@/index.css';

@Component
export default class Banner extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: true })
  file!: string;

  @Prop({ type: String, required: true })
  link!: string;

  get image(): string {
    return require('@/assets/banners/' + this.file);
  }
}
