
import { Component, Vue } from 'vue-property-decorator';
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';

@Component({
  components: {
    TwitterIcon,
  },
})
export default class App extends Vue {}
