
import { Component, Vue } from "vue-property-decorator";
import Banner from "@/components/Banner.vue";
import Account from "@/components/Account.vue";
import Divider from "@/components/Divider.vue";

@Component({
  components: {
    Banner,
    Account,
    Divider,
  },
})
export default class Home extends Vue {
  celebration_banners = [
    {
      title: "誕生日2018",
      file: "link_001.png",
      link: "https://booth.pm/ja/items/1114927",
    },
    {
      title: "1周年記念",
      file: "link_002.png",
      link: "https://akikunsuki.com/games/memories/",
    },
    {
      title: "誕生日2019",
      file: "link_003.png",
      link: "https://booth.pm/ja/items/1693665",
    },
    {
      title: "2周年記念",
      file: "link_004.png",
      link: "https://akikunsuki.com/2nd_anniversary/",
    },
    {
      title: "誕生日2020",
      file: "link_005.png",
      link: "https://akikunsuki.com/birthday2020/",
    },
    {
      title: "3周年記念",
      file: "link_006.png",
      link: "https://akikunsuki.com/3rd_anniversary/",
    },
    {
      title: "誕生日2021",
      file: "link_007.png",
      link: "https://akikunsuki.com/birthday2021/",
    },
    {
      title: "アキくんメモリーズDX",
      file: "link_008.png",
      link: "https://akikunsuki.com/games/memories_dx/",
    },
    {
      title: "誕生日2022",
      file: "link_009.png",
      link: "https://akikunsuki.com/birthday2022/",
    },
    {
      title: "5周年記念",
      file: "link_010.png",
      link: "https://akikunsuki.com/5th_anniversary/",
    },
    {
      title: "誕生日2023",
      file: "link_011.png",
      link: "https://akikunsuki.com/birthday2023/",
    },
    {
      title: "6周年記念",
      file: "link_013.png",
      link: "https://akikunsuki.com/6th_anniversary/",
    },
    {
      title: "陽だまりの記憶",
      file: "link_014.png",
      link: "https://akikunsuki.com/hidamari_memories/",
    },
  ];
  other_celebration_banners = [
    {
      title: "アキくんははらぐろ？のどぐろ？",
      file: "link_101.png",
      link: "https://akikunsuki.com/games/haraguro_or_nodoguro/",
    },
    {
      title: "鈴谷アキくん回線開通お祝い(2019/5）",
      file: "link_102.png",
      link: "https://akikunsuki.com/pdf/yosetti-2019-05-16.pdf",
    },
    {
      title: "このアキくんがすき",
      file: "link_103.png",
      link: "https://akikunsuki.com/i-love-this-akikun/",
    },
    {
      title: "モほすずありがとう",
      file: "link_104.png",
      link: "https://mohosuzu.saikyou.biz/",
    },
  ];
  games = [
    {
      title: "アキくんエスケープ！",
      file: "link_201.png",
      link: "https://game.nicovideo.jp/atsumaru/games/gm7949",
    },
    {
      title: "アキくんパズル",
      file: "link_202.png",
      link: "https://game.nicovideo.jp/atsumaru/games/gm13765",
    },
    {
      title: "ニャンパヤ",
      file: "link_203.png",
      link: "http://tept.sakura.ne.jp/Nyanpaya/",
    },
  ];
  fansites = [
    {
      title: "鈴谷ボタン",
      file: "link_301.png",
      link: "http://akikunsuki.starfree.jp/",
    },
    {
      title: "アキくんのおうた",
      file: "link_302.png",
      link: "https://yay4ya.github.io/akikunouta",
    },
  ];
}
