
import { Component, Prop, Vue } from 'vue-property-decorator';
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';
import YoutubeIcon from 'vue-material-design-icons/Youtube.vue';

@Component({
  components: {
    TwitterIcon,
    YoutubeIcon,
  }
})
export default class Banner extends Vue {
  @Prop({ type: String, required: true })
  type!: string;

  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: String, required: true })
  link!: string;

  @Prop({ type: String, required: true })
  file!: string;

  get image() {
    if (this.file.match(/^http/)) {
      return this.file;
    }
    return require('@/assets/accounts/' + this.file);
  }

  typeClass = {
    'youtube': this.type === 'YouTube',
    'twitter': this.type === 'Twitter',
  };
}
